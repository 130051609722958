import React, { useState } from 'react'
import { styles } from './styles'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { FormControl } from '@material-ui/core'
import Select from 'react-select'
import { BREAKPOINTS } from '../../constants'

interface ProductsSortProps {
  selectedFilter: any
  onFilterChange: () => void
  options: any
  label: string
  t: TFunction
}

const CHRProductsSort = ({
  t,
  onFilterChange,
  selectedFilter,
  options,
  label,
}: ProductsSortProps) => {
  const classes = styles()

  const colourStyles = {
    control: styles => ({
      ...styles,
      backgroundColor: 'transparent',
      width: '100%',
      padding: 0,
      marginLeft: 10,
      cursor: 'pointer',
      border: 'none !important',
      boxShadow: 'none',
      '&:hover': {
        border: 'none !important',
      },
    }),
    option: styles => {
      return {
        ...styles,
        fontFamily: 'Schnyder XL Light',
        fontSize: 30,
        padding: '0 0 0 10px',
        [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
          fontSize: 20,
        },
        [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
          fontSize: '1.25rem',
        },
      }
    },
    valueContainer: styles => ({
      height: 50,
    }),
    singleValue: styles => ({
      ...styles,
      top: 'unset',
      bottom: -25,
      fontFamily: 'Schnyder XL Light',
      fontSize: '1.875rem',
      fontWeight: 400,
      [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
        fontSize: '20px',
        bottom: -15,
      },
    }),
    placeholder: styles => ({
      display: 'none',
    }),
    input: styles => ({
      color: 'transparent',
    }),
    menu: styles => ({
      ...styles,
      width: '100%',
      left: 0,
    }),
  }

  return (
    <div>
      <FormControl className={classes.formControl}>
        <h4 className={classes.sortByText}>{label}</h4>
        <Select
          defaultValue={selectedFilter}
          value={selectedFilter}
          className={classes.selectList + ' select-list'}
          onChange={onFilterChange}
          options={options}
          styles={colourStyles}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          isSearchable={false}
        />
      </FormControl>
    </div>
  )
}

export default withTranslation()(CHRProductsSort)
