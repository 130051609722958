import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS } from '../../../constants'

export const styles = makeStyles(theme => ({
  leftFloat: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 100,
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      paddingTop: 0,
    },
  },
  mediaColumnImage: {
    maxWidth: '100%',
    position: 'absolute',
    top: 'auto',
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  mediaContainer: {
    position: 'relative',
    display: 'inline-block',
    width: '100%',
    height: 0,
    paddingBottom: '56.25%',
  },
  textContent: {
    width: '90%',
    margin: '0 auto',
    padding: '5% 0',
    paddingBottom: 0,
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 15,
  },
  mediaColumn: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    [`@media (min-width: ${BREAKPOINTS.xl}px)`]: {
      alignItems: 'flex-end',
    },
  },

  [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
    leftFloat: {
      flexDirection: 'row',
      paddingTop: 120,
    },
    textColumn: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '40%',
      paddingBottom: 0,
    },
    mediaColumn: {
      width: '55%',
    },
    flexEnd: {
      marginTop: 30,
    },
    textContent: {
      marginRight: 0,
    },
  },
  desktop: {
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      display: 'none',
    },
  },
}))
