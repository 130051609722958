import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles(theme => ({
  button: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    height: 54,
    padding: 0,
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    border: 'none',
    outline: 'none',
  },
  buttonLabel: {
    margin: 0,
    minWidth: 'fit-content',
    textTransform: 'lowercase',
    color: 'inherit',
  },
  buttonIcon: {
    marginTop: 5,
  },
  link: {
    textDecoration: 'none',
  },
}))
