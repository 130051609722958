import { makeStyles } from '@material-ui/core/styles'
import { FONTS } from '../../constants'

export const styles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    minWidth: 110,
    maxWidth: 343,
    position: 'relative',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectList: {
    ['& > select']: {
      padding: 0,
      paddingLeft: 10,
      paddingRight: 0,
      backgroundColor: 'unset !important',
      fontFamily: FONTS.Graphik.GraphikRegular,
      fontSize: '0.75rem',
      lineHeight: 'normal',
      ['& > option']: {
        fontFamily: FONTS.Graphik.GraphikRegular,
      },
    },
  },
  sortByText: {
    paddingLeft: 20,
    paddingRight: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: FONTS.Graphik.GraphikRegular,
    fontSize: '0.75rem',
    fontWeight: 400,
    position: 'absolute',
    bottom: 7.5,
    left: 0,
    margin: 0,
  },
}))
