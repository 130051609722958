import * as React from 'react'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { styles } from './styles'
import rightArrow from '../../assets/images/arrow-back.svg'
import leftArrow from '../../assets/images/arrow-left.svg'
import { Typography } from '@material-ui/core'
import { Link } from 'gatsby'

interface CHRArrowButtonProps {
  t: TFunction
  onClick: Function
  label: string
  link?: string
  outerLink?: boolean
  isLeftArrow?: boolean
  disabled?: boolean
}

const CHRArrowButton = ({
  t,
  label,
  link,
  outerLink,
  onClick,
  isLeftArrow,
  disabled,
}: CHRArrowButtonProps) => {
  const classes = styles()

  const generateButton = () => {
    const button = isLeftArrow ? (
      <>
        <img
          style={{ marginRight: '18px' }}
          src={leftArrow}
          className={classes.buttonIcon}
          alt="arrow icon"
        />
        <Typography
          variant="subtitle2"
          component="p"
          className={classes.buttonLabel}
        >
          {label}
        </Typography>
      </>
    ) : (
      <>
        <Typography
          variant="subtitle2"
          component="p"
          className={classes.buttonLabel}
        >
          {label}
        </Typography>
        <img
          style={{ marginLeft: '18px' }}
          src={rightArrow}
          className={classes.buttonIcon}
          alt="arrow icon"
        />
      </>
    )

    if (onClick !== undefined) {
      return (
        <button
          style={{
            opacity: disabled ? 0.5 : 1,
          }}
          disabled={disabled}
          onClick={() => onClick()}
          className={classes.button}
        >
          {button}
        </button>
      )
    } else if (outerLink) {
      return (
        <a target="_blank" href={link} className={classes.link}>
          <div className={classes.button}>{button}</div>
        </a>
      )
    } else {
      return (
        <Link
          rel="preconnect"
          to={link}
          className={`${classes.link} ${classes.button}`}
        >
          {button}
        </Link>
      )
    }
  }

  return <>{generateButton()}</>
}

export default withTranslation()(CHRArrowButton)
