import * as React from 'react'
import { styles } from './styles'
import CHRArrowButton from '../../arrowButton'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

interface MiddleSectionProps {
  rightChildren: React.ReactNode
  buttonLabel?: string
  t: TFunction
  handleArrowClick?: Function
  image: any
}

const MiddleSection = ({
  rightChildren,
  image,
  handleArrowClick,
  buttonLabel,
  t,
}: MiddleSectionProps) => {
  const classes = styles()

  return (
    <div className={classes.leftFloat}>
      <div className={classes.mediaColumn}>
        <div className={classes.mediaContainer}>
          <img
            src={image}
            alt={t('imageAlts.placeholder')}
            className={classes.mediaColumnImage}
          />
        </div>
      </div>
      <article className={classes.textColumn}>
        <div className={classes.textContent}>
          {rightChildren}
          <div className={classes.flexEnd}>
            <CHRArrowButton
              label={buttonLabel ? buttonLabel : t('shared.addChirpyest')}
              onClick={handleArrowClick}
              hoverEffects={true}
            />
          </div>
        </div>
      </article>
    </div>
  )
}

export default withTranslation()(MiddleSection)
